import React from "react";
import BodyClassName from "react-body-classname";
import Layout from "../components/layout";
import Footer from "../components/footer";
import SEO from "../components/seo";
import { useIntl, Link, navigate } from "gatsby-plugin-intl";
import { connect } from "react-redux";
import Tools from "../classes/tools";

const Inversores = ({ s }) => {
	const intl = useIntl();

	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	return (
		<>
			<BodyClassName className="inversores">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title={Tools.convertString(s.inversores?.title)} />
					<main className="page">
						<article className="bg-img">
							<h1 className="white-text prequel-font">
								¿Quieres participar
								<br />
								en SYB?
							</h1>
							<picture>
								<source srcSet="/img/bg/inversores-desktop.jpg" media="(min-width: 768px)" />
								<img srcSet="/img/bg/inversores.jpg" alt={``} />
							</picture>
						</article>

						<article className="box bg-white text">
							<div className="wrapper-text">
								<ul className="breadcrumbs white-bg">
									<li className="link">
										<Link to="/" className="ltblue-text-02">
											Home
										</Link>
									</li>
									<li className="active">
										<p className="lightgray-text-07">{Tools.convertString(s.inversores?.title)}</p>
									</li>
								</ul>
							</div>
							<p className="box">
								<b>Share Your Board</b>, es un propuesta de economía colaborativa para que los aficionados al
								surf puedan alquilar sus tablas a otros surfistas de paso que quieren aprovechar su visita
								turística para practicar por unas horas este deporte. Un proyecto único en el mundo y que
								revolucionará el mundo del surf.
							</p>
							<p className="box">
								<b>Un modelo de negocio innovador, sostenible y escalable de ‘sharing economy’.</b>
							</p>
							<p className="box">
								<b>Share Your Board</b> es un proyecto global y escalable. Nuestra plataforma digital sirve de
								punto de unión entre surfistas con el objetivo de que compartan y saquen partido a sus tablas de
								surf. Una plataforma turistica con información de calidad sobre la oferta de hoteles y
								experiencias de los lugares referentes para la práctica de este deporte a nivel mundial, y que
								dé servicio a un mercado en continuo crecimiento a nivel mundial capaz de generar ingresos en
								servicios complementarios. En España existen más de 30.000 surfistas federados y 500 escuelas de
								surf, y se calcula que la comunidad global de surfistas está formada por unos 35 millones de
								personas que alquilan tablas de surf durante más de 2 millones de horas cada año.
							</p>
							<p className="box">
								<b>Taquillas físicas en las playas</b>
							</p>
							<p className="box">
								El proyecto es mucho más que una plataforma digital. En una segunda fase prevista para 2021, el
								objetivo es evolucionar esta propuesta multidispositivo y multicanal, con la tecnología más
								actual y el diseño más cuidado, hacia un modelo de negocio físico sostenible, similar al del
								alquiler de bicicletas o vehículos eléctricos en las ciudades, que permita a los surfistas poder
								alquilar también tablas de surf en las playas.
							</p>
							<p className="box">
								<b>Share Your Board</b> se convierte en un reclamo para los destinos turísticos del ámbito del
								surf y permite activar la economía de estos municipios, tanto a través de su oferta turística
								como de los shapers y los servicios complementarios para surfistas, incluidos los locales.
							</p>
							<p className="box">
								Actualmente <b>Share Your Board</b> ha abierto una ronda de inversión para poder acometer la
								Fase 2 del proyecto, de taquillas físicas en las playas. Si estás interesado en conocer más
								sobre el proyecto escríbenos a{" "}
								<a href="mailto:hello@shareyourboard.app">hello@shareyourboard.app</a> y te podremos enviar la
								información más detallada.
							</p>
							<br />
							<br />
						</article>
						<Footer />
					</main>
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(Inversores);
